<script>
import { get } from 'vuex-pathify'
export default {
	lang: 'account',
	computed: {
		userData: get('shop/user'),
	},
	pageView: true,
}
</script>

<template>
	<UserpanelLayout :title="$lang('Mi cuenta')">
		<v-row>
			<v-col cols="12">
				<CardLayout :title="$lang('Datos de contacto')" :showIcon="false">
					<ContactData />
				</CardLayout>
			</v-col>
			<v-col cols="12" md="6">
				<CardLayout :title="$lang('Acceso')" :showIcon="false">
					<UserData />
				</CardLayout>
			</v-col>
			<v-col cols="12" md="6">
				<CardLayout :title="$lang('Cambiar contraseña')" :showIcon="false">
					<ChangePassword />
				</CardLayout>
			</v-col>
		</v-row>
	</UserpanelLayout>
</template>
