<script>
export default {
	lang: 'shop',
	data() {
		return {
			orders: [],
			loading: false,
		}
	},
	pageView: true,
	methods: {
		loadOrders() {
			this.$shopApi.get({
				url: '/user/orders',
				loading: (v) => (this.loading = v),
				onSuccess: ({ data }) => {
					this.orders = data.orders
				},
			})
		},
	},
	created() {
		this.loadOrders()
	},
}
</script>

<template>
	<UserpanelLayout :title="$lang('Mis Compras')">
		<div v-if="!loading && !orders.length" class="text-center py-16">
			<v-icon x-large color="grey lighten-1">mdi-emoticon-sad-outline</v-icon>
			<div class="font-weight-bold text-h5 mb-4">{{ 'No realizaste ninguna compra' | lang }}</div>
			<div class="mb-6 d-flex align-center justify-center">
				<v-icon color="grey lighten-1">mdi-cart-arrow-down</v-icon>
				<div class="ml-2 preline">
					{{ $langText('Agregá productos a tu carrito y\nrealizá tu primera compra!') }}
				</div>
			</div>
			<div>
				<Button class="primary" x-large @click="$shop.goShop()">
					{{ 'Ir a la Tienda' | lang }}
				</Button>
			</div>
		</div>
		<div v-else-if="loading" class="d-flex align-center justify-center py-16">
			<v-progress-circular size="50" indeterminate />
			<div class="ml-4">{{ 'Cargando tus compras' | lang }}</div>
		</div>
		<template v-else>
			<OrderListCard
				v-for="(order, i) of orders"
				:key="i"
				:order="order"
				:link-text="$lang('Ver detalle')"
				@linkClick="$router.push({ name: 'user.order-detail', params: { id: order.id } })"
			/>
		</template>
	</UserpanelLayout>
</template>

<style></style>
