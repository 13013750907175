<script>
import Vue from 'vue'

export default {
	lang: 'shop',
	name: 'OrderListCard',
	props: {
		order: Object,
		linkText: String,
		linkLoading: Boolean,
	},
	computed: {
		mainStatus() {
			return this.order.mainStatus
		},
		paymentStatus() {
			return this.order.paymentStatus
		},
		delivery() {
			return this.order.delivery
		},
		titleStatus() {
			return this.order.statusesInfo.title
		},
		currency() {
			return this.order.currency.sign
		},
	},
	methods: {
		gotoDetail() {
			this.$router.push({ name: 'user.order-detail', params: { id: this.order.id } })
		},
		filter(name, ...args) {
			return Vue.filter(name)(...args)
		},
	},
}
</script>

<template>
	<CardLayout>
		<template #title>
			<div
				class="px-2 rounded"
				:class="`${titleStatus.color} lighten-5 ${titleStatus.color}--text text--darken-2`"
			>
				{{ titleStatus.buyerName || titleStatus.name }}
			</div>
			<v-spacer />
			<Button
				v-if="linkText"
				@click.prevent.stop="$emit('linkClick')"
				color="link"
				text
				small
				:loading="linkLoading"
			>
				{{ linkText }}
			</Button>
		</template>
		<v-row dense>
			<v-col cols="12" md="6">
				<div class="item-key">{{ 'Código de compra' | lang }}</div>
				<div class="item-value">#{{ order.code }}</div>
			</v-col>
			<v-col cols="12" md="6">
				<div class="item-key">{{ 'Fecha en que realizaste el pedido' | lang }}</div>
				<div class="item-value">
					{{ order.statusesAt.main.confirmed | datetime }}
				</div>
			</v-col>
			<v-col cols="12" md="6">
				<div class="item-key">{{ 'Total de la compra' | lang }}</div>
				<div class="item-value">
					<PriceText
						:amount="order.total"
						zero-decimals
						style="font-size: 1.4rem"
						:currency="currency"
					/>
				</div>
			</v-col>
			<v-col cols="12" md="6">
				<div class="item-key">{{ 'Estado del pago' | lang }}</div>
				<div class="item-value">
					<span v-if="paymentStatus == 'paid'">
						{{
							'Tu pago se acreditó el día {date} a las {time}'
								| lang({
									date: filter('date', order.statusesAt.payment.paid),
									time: filter('time', order.statusesAt.payment.paid),
								})
						}}
					</span>
					<span v-else-if="paymentStatus == 'refunded'">
						{{ 'Se ha realizado la devolución de tu pago' | lang }}
					</span>
					<span v-else-if="mainStatus == 'canceled'"> {{ 'Tu compra fue cancelada' | lang }} </span>
					<span v-else> {{ 'Pendiente de pago' | lang }} </span>
				</div>
			</v-col>
			<v-col cols="12" v-if="delivery">
				<div class="item-key">{{ 'Entrega de productos' | lang }}</div>
				<div class="item-value">{{ delivery.methodName }} - {{ delivery.optionName }}</div>
			</v-col>
		</v-row>
	</CardLayout>
</template>

<style scoped>
.item-key {
	padding: 2px 6px;
	font-weight: bold;
	background-color: #f3f3f3;
	border-radius: 4px;
	display: inline-block;
}
.item-value {
	padding: 6px 6px;
}
</style>
