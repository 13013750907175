<script>
export default {
	lang: 'shop',
	data() {
		return {
			order: null,
			loading: false,
			cardLinkLoading: false,
		}
	},
	pageView() {
		let url = this.$router.resolve({
			name: this.$route.name,
			params: {
				...this.$route.params,
				id: '__ID__',
			},
			query: this.$route.query,
		}).href
		return {
			location: url,
		}
	},
	computed: {
		isPaid() {
			return this.order?.paymentStatus == 'paid'
		},
		canPay() {
			return this.order?.mainStatus == 'confirmed' && this.order?.paymentStatus == 'pending'
		},
	},
	methods: {
		loadOrder() {
			this.$shopApi.get({
				url: `/user/order/${this.$route.params.id}`,
				loading: (v) => (this.loading = v),
				onSuccess: ({ data, options }) => {
					if (!data.order) {
						options.abort()
						this.$router.replace({ name: 'user.orders' })
						return
					}
					this.order = data.order
				},
			})
		},
	},
	created() {
		this.loadOrder()
	},
}
</script>

<template>
	<UserpanelLayout :title="$lang('Detalle de la compra')">
		<div v-if="loading" class="d-flex align-center justify-center py-16">
			<v-progress-circular size="50" indeterminate />
			<div class="ml-4">{{ 'Cargando el detalle de la compra' | lang }}</div>
		</div>
		<template v-else>
			<v-row>
				<v-col cols="12">
					<OrderListCard :order="order" />
				</v-col>
				<v-col cols="12" v-if="canPay">
					<PaymentInstructions :order="order" />
				</v-col>
				<v-col cols="12">
					<CheckoutConfirmCard-payment :order="order" />
				</v-col>
				<v-col cols="12" v-if="isPaid">
					<OrderDigitalItemsCard :order="order" />
				</v-col>
				<v-col cols="12" v-if="order.delivery">
					<CheckoutConfirmCard-delivery :order="order" />
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="5">
					<v-row>
						<v-col cols="12">
							<CheckoutConfirmCard-discount :order="order" />
						</v-col>
						<v-col cols="12">
							<CheckoutConfirmCard-contact :order="order" />
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="7">
					<OrderSummary :order="order" />
				</v-col>
			</v-row>
		</template>
	</UserpanelLayout>
</template>

<style></style>
