<script>
import { get } from 'vuex-pathify'
export default {
	lang: 'account',
	name: 'UserData',
	computed: {
		userData: get('shop/user'),
		hasGoogleAccess() {
			return this.userData.googleId ? this.$lang('Sí') : this.$lang('No')
		},
		hasFacebookAccess() {
			return this.userData.facebookId ? this.$lang('Sí') : this.$lang('No')
		},
	},
}
</script>

<template>
	<div>
		<div class="mb-3">
			<div class="font-weight-bold">
				{{ 'Email' | lang }}
			</div>
			<div>
				{{ userData.accountEmail }}
			</div>
		</div>
		<div>
			<div class="mb-3">
				<div class="font-weight-bold">
					{{ 'Acceso con Google' | lang }}
				</div>
				<div>
					{{ hasGoogleAccess }}
				</div>
			</div>
			<div>
				<div class="font-weight-bold">
					{{ 'Acceso con Facebook' | lang }}
				</div>
				<div>
					{{ hasFacebookAccess }}
				</div>
			</div>
		</div>
	</div>
</template>

<style></style>
